@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {

  :root {
    --font-inter: 'Inter', sans-serif;
  }
  html {
    font-family: var(--font-inter), sans-serif;
  }

  body {
    background: black;
  }

  .mask-radial-faded {
    mask-image: radial-gradient(circle at center center, black, transparent 80%)
  }

  .mask-linear-faded {
    mask-image: linear-gradient(black, transparent 70%)
  }

  .bg-radial-faded {
    background-image: radial-gradient(circle at bottom center, var(--color), transparent 70%);
  }

}
